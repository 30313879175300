@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: black;
  color: white;
}

body::-webkit-scrollbar,
.scrollbar-styles::-webkit-scrollbar {
  width: 0.5rem;
  background-color: hsla(0, 0%, 100%, 0.1);
}
body::-webkit-scrollbar-thumb,
.scrollbar-styles::-webkit-scrollbar-thumb {
  border-radius: 0.375rem;
  background-color: #0086d4;
}
body::-webkit-scrollbar-thumb,
.scrollbar-styles::-webkit-scrollbar-thumb {
  border-radius: 0.375rem;
  background-color: #0086d4;
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  html body #embed-player-wrapper [data-radix-popper-content-wrapper] {
    transform: none !important;
  }
}

// override styles of package
html body .lazy-load-image-background {
  display: block !important;
}

$font-sans-serif: "Gordita", "Avenir", "Helvetica Neue", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol";
$font-size-base: 15;
$font-size-small: 13;
$font-size-large: 18;
$font-size-h1: 64;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-black: 900;
$line-height-base: 1.75;
$letter-spacing-headings: -0.025em;

@font-face {
  font-display: swap;
  font-family: Gordita;
  font-style: normal;
  font-weight: $font-weight-light;
  src: url("https://cdn.plyr.io/static/fonts/gordita-light.woff2") format("woff2"),
    url("https://cdn.plyr.io/static/fonts/gordita-light.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Gordita;
  font-style: normal;
  font-weight: $font-weight-regular;
  src: url("https://cdn.plyr.io/static/fonts/gordita-regular.woff2") format("woff2"),
    url("https://cdn.plyr.io/static/fonts/gordita-regular.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Gordita;
  font-style: normal;
  font-weight: $font-weight-medium;
  src: url("https://cdn.plyr.io/static/fonts/gordita-medium.woff2") format("woff2"),
    url("https://cdn.plyr.io/static/fonts/gordita-medium.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Gordita;
  font-style: normal;
  font-weight: $font-weight-bold;
  src: url("https://cdn.plyr.io/static/fonts/gordita-bold.woff2") format("woff2"),
    url("https://cdn.plyr.io/static/fonts/gordita-bold.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Gordita;
  font-style: normal;
  font-weight: $font-weight-black;
  src: url("https://cdn.plyr.io/static/fonts/gordita-black.woff2") format("woff2"),
    url("https://cdn.plyr.io/static/fonts/gordita-black.woff") format("woff");
}

body {
  font-family: Gordita;
  font-weight: 500;
}
